import React from 'react'
import { NavLink } from 'react-router-dom'
import Hexagon from 'react-hexagon'
import { useHistory } from "react-router-dom";


const Card = ({ key, item }) => {
  let history = useHistory();

  return (
      <div id='serviceCard' className='col-md-4 col-10 mx-auto '>
        <div className='card '>
          <div style={{minHeight:247,display: "flex",
            alignItems: "center"}}>
            <img style={{
              maxHeight:247,
              width:"auto",
              maxWidth:"100%",
              margin:"0 auto"
            }} src={item.imgsrc} className='card-img-top' alt={item.title} />
          </div>
          <div className='card-body' style={{
            display:"flex",
            flexDirection:"column",
            justifyContent:"center"
          }}>
            <div style={{display:"flex", flexWrap:"wrap",justifyContent:"space-around"}}>
            {item.skills?.map((value,index)=>{
              return(
                  <img width={60} src={value}/>
                  // <div style={{padding:7,marginTop:5, border:"1px solid", borderRadius:30}}>
                  //   {value}
                  // </div>
              )
            })}
            </div>
            <h5 style={{margin:15, textAlign:"center"}} className='card-title fw-bold'>{item.title}</h5>
            <div style={{display:"flex", justifyContent:"center", cursor:"pointer"}}>
                <div onClick={()=>{
                  history.push({
                    pathname: '/project/'+item.nameUri,
                    state: { item: item },
                  });
                }} className='btn-get-started'>
                  En savoir plus
                </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default Card
