import React, { useState } from 'react'
import contact from './images/contactusv2.png'
import mail from './images/icons8-mail-50.png'
import phone from './images/icons8-phone-50.png'
import pin from './images/icons8-pin-64.png'

const Contact = () => {
  const [data, setData] = useState({
    fullname: '',
    phone: '',
    email: '',
    message: '',
  })

  const InputEvent = (event) => {
    const { name, value } = event.target
    setData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      }
    })
  }

  const formSubmit = (e) => {
    e.preventDefault()
    alert(`Thank You for submitting the form:\n 
    Nom : ${data.fullname}\n
    Numéro de téléphone : ${data.phone} \n
    Email : ${data.email}\n
    Votre message :\n ${data.message} `)

  }

  return (
    <>
      <div id="contacts" className='my-5'>
        <h1 className='text-center'>Contactez-Nous</h1>
      </div>
      <div id='contact' className='container contact_div'>
        <div className='row'>
          <div className='col-md-6 col-10 mx-auto'>
            <img src={contact} alt='homeimg' className='img-fluid animated' />
          </div>
          <div style={{display:"flex",flexDirection:"column", alignItems:"center", justifyContent:"center"}} className='col-md-5 col-10 order-1 order-lg-2 mx-auto'>
            <div style={{display:"flex", width:300, marginBottom:20}}>
              <div style={{width:50, display:"flex", flexDirection:"column", alignItems:"center"}}><img src={pin}/></div>
              <div style={{display:"flex", flexDirection:"column", marginLeft:10}}>
                <span style={{fontWeight:900}}>Nos bureaux</span>
                <span>54 avenue de la marjolaine 34110 Frontignan</span>
              </div>
            </div>
            <div style={{display:"flex",  width:300, marginBottom:20}}>
              <div style={{width:50}}><img src={mail}/></div>
              <div style={{display:"flex", flexDirection:"column", marginLeft:10}}>
                <span style={{fontWeight:900}}>Nous contacter</span>
                <span>contact@honey-digital.fr</span>
              </div>
            </div>
            <div style={{display:"flex",  width:300, marginBottom:20}}>
              <div style={{width:50}}><img src={phone}/></div>
              <div style={{display:"flex", flexDirection:"column", marginLeft:10}}>
                <span style={{fontWeight:900}}><strong>Nous téléphoner</strong></span>
                <span>06 25 59 02 11</span>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default Contact
