import React from 'react'
import HomeandAboutHead from './HomeAndAboutHead'
//import home from '../src/images/home.png'
import home from '../src/images/home_newcolor.png'

const Home = () => {
  return (
    <>
      <HomeandAboutHead
        headtitle={'Nos experts au service '}
        headtitle2={'de vos idées'}
        headSubTitle={'Travailler ensemble vers un but commun'}
        headButton={'Get Started'}
        headButtonLink={'/service'}
        headImg={home}
      />
    </>
  )
}

export default Home
