import React from 'react'
import HomeAndAboutHead from './HomeAndAboutHead'
//import about from '../src/images/about.png'
import about from '../src/images/newcolorabout.png'
import learning from '../src/images/learning.png'

const Formation = () => {
  return (
    <div id="formation">
      <HomeAndAboutHead
        headtitle={'Formation'}
        headtitle2={''}
        headSubTitle={
          "Chez Honey Digital, nous sommes fiers de proposer une formation complète sur WordPress pour nos clients.\n" +
          "WordPress est l'un des systèmes de gestion de contenu les plus populaires au monde,\n" +
          "utilisé par des millions de sites web à travers le globe. Avec notre formation, vous pourrez découvrir les fonctionnalités de base de WordPress, " +
          "ainsi que des astuces avancées pour personnaliser votre site web à votre goût. Que vous soyez un débutant ou un utilisateur expérimenté, notre formation vous permettra de tirer le meilleur parti de ce puissant outil pour créer un site web professionnel et efficace. \n\nContactez-nous dès maintenant pour découvrir les possibilités de WordPress avec Honey Digital.\n"
        }
        headButton={'Connect Now'}
        headButtonLink={'/contact'}
        headImg={learning}
      />
    </div>
  )
}

export default Formation
