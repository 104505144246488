import React from 'react'
import { NavLink } from 'react-router-dom'
import { HashLink as Link } from 'react-router-hash-link';
import logo from '../src/images/logoHD.png'

const Navbar = () => {
  return (
    <>
      <div className='container-md nav_bg'>
        <div className='row'>
          <div className='col-11 col-lg-10 mx-auto'>
            <nav className='navbar navbar-expand-lg navbar-light bg-light'>
              <div className='container-md'>
                <NavLink className='navbar-brand' to='/'>
                  <img style={{width:200}} src={logo}/>
                </NavLink>
                <button
                  className='navbar-toggler'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#navbarSupportedContent'
                  aria-controls='navbarSupportedContent'
                  aria-expanded='false'
                  aria-label='Toggle navigation'
                >
                  <span className='navbar-toggler-icon'></span>
                </button>
                <div
                  className='collapse navbar-collapse'
                  id='navbarSupportedContent'
                >
                  <ul className='navbar-nav ms-auto mb-2 mb-lg-0'>
                    <li className='nav-item'>
                      <Link
                        activeClassName='menu_active'
                        exact
                        className='nav-link'
                        to='/'
                      >
                        Accueil
                      </Link>
                    </li>
                    <li className='nav-item'>
                      <Link
                        activeClassName='menu_active'
                        className='nav-link'
                        to='/#service'
                      >
                        Réalisations
                      </Link>
                    </li>
                    <li className='nav-item'>
                      <Link
                        activeClassName='menu_active'
                        className='nav-link'
                        to='/#formation'
                      >
                        Formations
                      </Link>
                    </li>
                    <li className='nav-item'>
                      <Link
                        activeClassName='menu_active'
                        className='nav-link'
                        to='/#about'
                      >
                        À propos de nous
                      </Link>
                    </li>
                    <li className='nav-item'>
                      <Link
                        activeClassName='menu_active'
                        className='nav-link'
                        to='/#contacts'
                      >
                        Contact
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  )
}

export default Navbar
