import React from 'react'
import Card from './Card'
import CardData from './CardData'
const Service = () => {
  return (
    <div id="service">
      <div className='my-5'>
        <h1 className='text-center'>Nos Réalisations</h1>
      </div>
        <div className="test">
      <div className='container-md mb-5'>
        <div className='row'>
          <div className='col-11 col-lg-8 mx-auto'>
            <div className='row gy-4'>
              {CardData.map((value, index) => {
                return (
                    // <span className={"hex"+index}>
                  <Card
                    key={value.title+index}
                    item={value}
                  />
                    // </span>
                )
              })}
        </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Service
