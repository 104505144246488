import website from './images/website.jpg'
import webapp from './images/webapp.jpg'

import cafeR from './images/crLogo.png'
import vinR from './images/vrLogo.jpeg'
import hubnmail from './images/hubnmaillogo.png'
import pandazen from './images/pandazenLogo.png'
import cagette from './images/logoCagette.png'
import lasalle from './images/complexLasalle.png'
import scmb from './images/logoscmb.jpeg'
import rz from './images/rzprono.jpeg'
import vignitus from './images/vignitusLogo.png'
import bowling from './images/Bowlingstar-logo.jpeg'
import reactJS from './images/ReactJS.png'
import reactNative from './images/ReactNative.png'
import Wordpress from './images/Wordpress.png'
import Woocommerce from './images/woocommerce.png'
import Python from './images/Python.png'
import jQuery from './images/jQuery.png'
import nodeJS from './images/nodeJS.png'
import Symfony from './images/Symfony.png'
import BowlingImg from './images/bowlingimg.png'
import VinRichardImg from './images/eproVinR.png'
import CafeRichardImg from './images/cafeRichard.png'
import Portfoliohubnmail from './images/portfoliohubnmail.png'
import lasalleScreen from './images/lasallescreen.png'
import portfoliomcc from './images/portfoliomcc.png'
import portfoliopandazen from './images/portfoliopandazen.png'
import portfolioramirez from './images/portfolioramirez.png'
import portfolioscmb from './images/portfolio-scmb.png'
import sap from './images/sap-logo-svg.svg'
import fmfa from './images/logo_fmfa.png'
import portfoliofmfa from './images/portfolio-fmfa.png'
import hfilogo from './images/hfilogo.png'
import portfoliohmi from './images/portfoliohmi.png'
import comline from './images/logocomline.png'
import portfoliocomline from './images/portfoliocomline.png'
import comline1 from './images/image-4.png'
import comline2 from './images/image-5.png'
import comline3 from './images/image-6.png'
import comline4 from './images/image-7.png'
import comline5 from './images/image-8.png'
import comline6 from './images/image-9.png'
import comline7 from './images/image-10.png'
import comline8 from './images/image-11.png'
import comline9 from './images/image-12.png'
import comline10 from './images/image-13.png'
import portfoliovignitus from './images/portfoliovignitus.png'
import ce1 from './images/ce-1.png'
import ce2 from './images/ce-2.png'
import ce3 from './images/ce-3.png'
import ce4 from './images/ce-4.png'
import logoce from './images/logo-ce.png'
import bgce from './images/cebg.png'



const CardData = [
  {
    imgsrc: bowling,
    imgcover:BowlingImg,
    title: 'Bowling Star',
    nameUri: 'bowlingstar',
    href:'https://bowlingstar.fr',
    date:"2020 - aujourd'hui",
    skills:[Symfony,nodeJS,Python,jQuery,reactNative],
    description:"Avec l’avènement d’internet, des réseaux sociaux et du téléphone mobile, il était indispensable de repenser un espace de loisir qui s’adapte aux personnes et à leur mode de consommation.\n" +
        "</br>Suite à un travail en immersion dans le monde du bowling, au plus près des process, des vrais besoins, nous avons pu élaborer une solution regroupant plusieurs modules interconnectés permettant de digitaliser l’ensemble de l’activité, de la réservation à distance au paiement, de l’accueil \"automatisé\" des clients à la commande de snacking / bar, en passant par la supervision des espace de jeux\n" +
        "</br></br><ul><li>Site institutionnel</li>\n" +
        "<li>Interface de réservation en ligne</li>\n" +
        "<li>Application mobile</li>\n" +
        "<li>Borne d'accueil</li>\n" +
        "<li>Ecran d’orientation de la file d’attente</li>\n" +
        "<li>Ecran piste avec commande boissons / snacking et gestion des parties</li>\n" +
        "<li>Phablette équipier</li>\n" +
        "<li>Meuble de gestion automatique des billards</li>\n" +
        "<li>Back office de gestion de l’établissement</li>\n</ul>" +
        "</br>" +
        "Tout a été pensé pour optimiser la gestion des espaces de loisirs et des ressources humaines nécessaires au bon fonctionnement des établissements.  \n" +
        "</br>" +
        "Les grandes plus values de cette solution digitale unanimement reconnues par les directeurs de centres :<br/>" +
        "</br><ul><li>réduction des frais de personnel</li>" +
        "<li>augmentation des amplitudes horaires d’ouverture</li>" +
        "<li>gain de temps dans la mise en place des joueurs</li>" +
        "<li>simplification des interactions humaines</li> </ul>",
    subTitle:
      'La solution développée par nos équipes a été élaborée en immersion sur 4 ans et a évolué selon les obstacles et évolutions rencontrés que ce soit techniques ou ergonomiques.' +
        ' Site institutionnel, Interface de réservation en ligne, Application mobile, Borne d\'accueil, Ecran d’orientation, Ecran piste avec casiers à chaussures ou sans, Phablette équipier, Meuble billard, Back office de gestion de l’établissement',
  },{
    imgsrc: cafeR,
    imgcover:CafeRichardImg,
    title: 'Cafés Richard',
    nameUri: 'cafesrichard',
    href:'',
    date:"2019 - 2020",
    description:"Nous avons été mandaté par une agence digitale chargée de la mise en place d’un portail de commande B2B pour les leaders du CHR, cafés et vins richards, qui était alors dans une impasse. </br>" +
        "Le but de ce portail était de permettre aux responsables d’achat des CHR de pouvoir passer leurs commandes de façon autonome tout en bénéficiant d’outils orientés à la facilitation des process de commandes (mercuriales, relances, placement de produits, mise en avant de promotions, …). Une sorte de drive optimisée à l’usage B2B.</br>" +
        "La plus grande difficulté était d’adapter une solution digitale à un outil vieux de plus de 20 ans qui était fonctionnel et qui avait nécessité de gros investissements. </br>" +
        "Il a donc fallu se connecter aux solutions SAP utilisées en interne.</br>" +
        "</br>" +
        "Grâce à notre savoir-faire, nous avons pu faire en sorte que soit livrée la plateforme respectant les délais ainsi que les spécifications techniques et fonctionnelles du client.\n" +
        "</br></br>Notre implication a été multifactorielle:</br>\n" +
        "<ul><li>participation aux CODIR</li>\n" +
        "<li>Interventions MOA et MOE</li>\n" +
        "<li>conseil sur les choix technologiques</li>\n" +
        "<li>amélioration des use-case</li>\n" +
        "<li>refactorisation et correction des sources déjà en place.</li></ul> \n" +
        "</br></br>" +
        "Les plus values de la solution :</br>\n" +
        "<ul><li>modernisation des flux de commandes</li>\n" +
        "<li>autonomie des clients pour les commandes</li>\n" +
        "<li>recentrage des commerciaux sur la prospection</li></ul>\n",
    skills:[Symfony, jQuery,sap],
    subTitle:
        'Café Richard, ',
  },{
    imgsrc: vinR,
    imgcover:VinRichardImg,
    title: 'Vins Richard',
    nameUri: 'vinsrichard',
    skills:[Symfony, jQuery,sap],
    date:"2019 - 2020",
    href:'',
    description:"Nous avons été mandaté par une agence digitale chargée de la mise en place d’un portail de commande B2B pour les leaders du CHR, cafés et vins richards, qui était alors dans une impasse. </br>" +
        "Le but de ce portail était de permettre aux responsables d’achat des CHR de pouvoir passer leurs commandes de façon autonome tout en bénéficiant d’outils orientés à la facilitation des process de commandes (mercuriales, relances, placement de produits, mise en avant de promotions, …). Une sorte de drive optimisée à l’usage B2B.</br>" +
        "La plus grande difficulté était d’adapter une solution digitale à un outil vieux de plus de 20 ans qui était fonctionnel et qui avait nécessité de gros investissements. </br>" +
        "Il a donc fallu se connecter aux solutions SAP utilisées en interne.</br>" +
        "</br>" +
        "Grâce à notre savoir-faire, nous avons pu faire en sorte que soit livrée la plateforme respectant les délais ainsi que les spécifications techniques et fonctionnelles du client.\n" +
        "</br></br>Notre implication a été multifactorielle:</br>\n" +
        "<ul><li>participation aux CODIR</li>\n" +
        "<li>Interventions MOA et MOE</li>\n" +
        "<li>conseil sur les choix technologiques</li>\n" +
        "<li>amélioration des use-case</li>\n" +
        "<li>refactorisation et correction des sources déjà en place.</li></ul> \n" +
        "</br></br>" +
        "Les plus values de la solution :</br>\n" +
        "<ul><li>modernisation des flux de commandes</li>\n" +
        "<li>autonomie des clients pour les commandes</li>\n" +
        "<li>recentrage des commerciaux sur la prospection</li></ul>\n",
    subTitle:
        '',
  },
  {
    imgsrc: vignitus,
    imgcover: portfoliovignitus,
    title: 'Vignitus',
    nameUri: 'vignitus',
    date:"2022",
    href:'https://vignitus.fr',
    skills:[reactNative,nodeJS,reactJS],
    description: "Vignitus est une market place d’achat / vente de bouteilles de vin. Inspiré de Vinted, Il est possible d’acheter des “boost” pour mettre en avant des produits ou son propre catalogue sous forme de vitrine. Des notifications et de l’emailing permettent de ne louper aucune vente.\n" +
        "\n" +
        "<ul><li>Site Web node JS + React JS</li>" +
        "<li>application mobile React Native</li>" +
        "<li>solution multi transporteurs</li>" +
        "<li>système de promotions</li>" +
        "<li>système d'enchères</li>" +
        "<li>messagerie tchat vendeur / acheteur</li></ul>",
    subTitle:
      'Vignitus est une plateformes de vente de bouteille de vin entre professionnel et également particulier.',
  },
  {
    imgsrc: scmb,
    imgcover: portfolioscmb,
    title: 'SeCoucherMoinsBete',
    nameUri: 'secouchermoinsbete',
    href:'https://apps.apple.com/fr/app/se-coucher-moins-b%C3%AAte/id359104281?platform=iphone',
    skills:[reactNative, Symfony],
    date:"2021 - 2022",
    description: "SeCoucherMoinsBete était un site web freemium, d'anecdotes en ligne.</br>" +
        "Le client, souhaitant améliorer l’ensemble de ses services, nous a demandé d’intégrer plusieurs modules afin d’en faire une plateforme plus dynamique et relancer son concept :\n" +
        "<ul><li>système d’abonnement annuel via Revenuecat</li>\n" +
        "<li>système de notifications géré avec Wonderpush</li>\n" +
        "<li>gestion du support via Helpscout Beacon</li>\n" +
        "<li>applications mobiles</li></ul>\n" +
        "</br>" +
        "Nous avons su nous adapter aux outils tiers présélectionnés par le client en respectant des délais très serrés." +
        "</br></br>" +
        "Les plus values apportées à la solution :</br>" +
        "<ul><li>rafraîchissement des usages</li>" +
        "<li>relance du nombre de vues</li>" +
        "<li>augmentation du chiffre d’affaire</li>" +
        "<li>meilleur suivi des flux</li></ul>",
    subTitle:
      'SeCoucherMoinsBete est une plateforme d\'anecdotes en ligne, nous avons développé les applications mobiles',
  },
  {
    imgsrc: rz,
    imgcover: portfolioramirez,
    title: 'Ramirez Prono',
    nameUri: 'ramirezprono',
    date:"2018",
    skills:[reactNative],
    description:"Site Web et Application mobile (IOS et Android) de conseil en paris sportifs freemium qui suggère des paris gratuitement (accessible à tous) et d’autres en “VIP” avec un abonnement mensuel.\n" +
        "</br>La solution brasse près de 10K abonnés et  propose en moyenne 3 paris par jour." +
        "<ul><li>application mobile en React Native</li>" +
        "<li>paiement via Payline</li>" +
        "<li>bases de données Firebase</li></ul>" +
        "</br>" +
        "Le succès de la solution mise en place a permis une migration de l’activité vers les réseaux Discord en partenariat avec les acteurs principaux du pari en ligne (Winamax, BetClic, …) ce qui s’avère beaucoup plus rémunérateur.",
    subTitle: 'Application mobile de conseil en paris sportifs.',
  },
  {
    imgsrc: hubnmail,
    imgcover:Portfoliohubnmail,
    title: 'Hubn\'Mail',
    nameUri: 'hubnmail',
    href:'https://hubnmail.hubnup.fr/',
    date:"2020",
    skills:[nodeJS,jQuery],
    description:"Editeur Emailing, permet d’envoyer des cartes, type carte de vœux, à des listes de destinataires, avec texte personnalisé selon des filtres.\n" +
        "<ul><li>plateforme web nodejs + jquery</li>" +
        "<li>mailing via API send in blue</li>" +
        "<li>suivi de reception / lecture</li>" +
        "<li>Business Intelligence</li></ul>" ,
    subTitle:
      'Hubn\'Mail est une plateforme développée pour la société Hubn\'up pour envoyé des emails personnalisé à une liste de contact',
  },
  {
    imgsrc: pandazen,
    imgcover: portfoliopandazen,
    title: 'LePandazen',
    nameUri: 'lepandazen',
    href:'https://lepandazen.fr',
    date:"2020",
    skills:[Wordpress,Woocommerce],
    description: "Ecommerce de vente de CBD développé sous Wordpress / Woocommerce\n" +
        "Le CBD suivant une législation particulière, nous avons dû nous adapter et mettre en place un paiement CB via Viva Wallet qui est plus permissif.\n" +
        "</br>" +
        "<ul><li>gestion des stocks</li>" +
        "<li>gestion des fournisseurs</li>" +
        "<li>gestion des expéditions</li>" +
        "<li>suivi clientèle</li>" +
        "<li>suivie des envois</li>" +
        "<li>système de promotions</li>" +
        "<li>gestion de la fidélité</li></ul>",
    subTitle:
      'LePandazen est une boutique en ligne de vente de CBD.',
  },{
    imgsrc: lasalle,
    imgcover:lasalleScreen,
    title: 'Complexe LaSalle',
    nameUri: 'complexelasalle',
    date:"2020",
    skills:[nodeJS, jQuery, reactNative],
    href:'',
    description: "Site et application mobile de gestion de salle de sport, permettant :\n" +
        "<ul><li>génération des emplois du temps des coachs</li>" +
        "<li>génération des emplois du temps des adhérents</li>" +
        "<li>génération des emplois du temps d’occupation des salles</li>" +
        "<li>génération des fiches de paie</li>" +
        "<li>tchat interne et l’agenda</li>" +
        "<li>notifications</li></ul>" +
        "</br>" +
        "Les plus values de la solution :\n" +
        "<ul><li>Gain de temps</li>" +
        "<li>Optimisation des plannings </li>" +
        "<li>Interactions 24/7</li></ul>",
    subTitle:
      'Application de gestion de salle de sport, permettant de générer les emplois du temps des coachs, génération des fiches de paie associées à un chat interne',
  },{
    imgsrc: cagette,
    imgcover: portfoliomcc,
    title: 'MaChouetteCagette',
    nameUri: 'machouettecagette',
    date:"2021",
    skills:[nodeJS, reactJS, reactNative],
    href:'https://machouettecagette.fr',
    description: "Market place de plusieurs clicks and collect qui met en relation les consommateurs (près de 3000)  avec des producteurs (près de 500) de proximité.\n" +
        "</br>" +
        "Les producteurs peuvent avoir accès à un espace “premium” en s’abonnant pour pouvoir vendre ou juste bénéficier d’une entrée annuaire gratuitement.\n" +
        "Le système de paiement des abonnements mensuels est géré avec la plateforme systempay.\n" +
        "<ul><li>Application mobile (React Native)</li>" +
        "<li>Site internet (node JS + React JS)</li></ul>" +
        "</br>" +
        "Les plus values de la solution :" +
        "<ul><li>modernisation du commerce de proximité et des circuits courts</li>" +
        "<li>augmentation de visibilité des producteurs</li>" +
        "<li>commandes possibles 24/7</li></ul>",
    subTitle:
      'MaChouetteCagette, met en relation les consomateurs avec les producteurs pret de chez eux.',
  },{
    imgsrc: fmfa,
    imgcover: portfoliofmfa,
    title: 'FMFA7',
    nameUri: 'fmfa7',
    date:"2012 - aujourd'hui",
    skills:[jQuery, Symfony],
    href:'https://www.fmfa7.fr',
    description: "Plateforme WEB de gestion d’un championnat départemental, regroupant :\n" +
        "<ul><li>3000 joueurs</li>" +
        "<li>1500 matchs</li>" +
        "<li>120 équipes</li>" +
        "<li>31 semaines de compétition</li>" +
        "<li>sur 55 stades</li></ul>" +
        "</br>" +
        "La solution développée permet :\n" +
        "<ul><li>la saisie de scores le soir même</li>" +
        "<li>la gestion des créneaux</li>" +
        "<li>la gestion du calendrier</li>" +
        "<li>la génération automatique du calendrier en fonction des créneaux</li>" +
        "<li>la gestion des scores</li>" +
        "<li>la gestion du fairplay</li>" +
        "<li>gestion des inscriptions</li>" +
        "<li>la gestion des résultats</li>" +
        "<li>le classement automatique en temps réel</li>" +
        "communication entre les responsables d’équipe - et l’organisation</li></ul>" +
        "</br>" +
        "" +
        "\n" +
        "L’application mobile est en cours de développement, elle sera opérationnelle pour la rentrée prochaine.\n",
    subTitle:
      '',
  },{
    imgsrc: hfilogo,
    imgcover: portfoliohmi,
    title: 'Hopitaux Facultes immobilier',
    nameUri: 'hopitauxfacultesimmobilier',
    date:"2021",
    skills:[Wordpress],
    href:'http://hopitauxfacultesimmobilier.com/',
    description: "Agence immobilière en ligne permettant :\n" +
        "<ul><li>la gestion des agents immobilier</li>" +
        "<li>la gestion des biens</li>" +
        "<li>la gestion des syndicats de copropriété</li>" +
        "<li>recherche par localisation, type de bien, budget</li>" +
        "<li>partage sur les réseaux sociaux</li></ul>",
    subTitle:
      '',
  },{
    imgsrc: comline,
    imgcover: portfoliocomline,
    title: 'COMLINE DIGITAL',
    nameUri: 'comlinedigital',
    date:"2020 - aujourd'hui",
    partenariat:true,
    skills:[],
    //href:'http://hopitauxfacultesimmobilier.com/',
    description: "Partenariat avec la société COMLINE DIGITAL.</br>" +
        "Nous équipons nos clients avec les produits de COMLINE DIGITAL et nous developpons des applicatifs pour leurs clients. ",
    subTitle:
      '',
    images:[comline1,comline2,comline3,comline4,comline5,comline6,comline7,comline8,comline9,comline10]
  },
  {
    imgsrc: logoce,
    imgcover: bgce,
    title: 'Solution ticket-ce',
    nameUri: 'ticket-ce',
    date:"2021 - aujourd'hui",
    skills:[],
    interne:true,
    //href:'http://hopitauxfacultesimmobilier.com/',
    description: "Découvrez la révolution dans la gestion des avantages du comité d'entreprise : notre solution de vente de ticket numérique. Avec notre plateforme innovante, nous avons pris en compte tous les besoins essentiels des comités d'entreprise pour simplifier leur gestion et offrir une expérience exceptionnelle aux employés. Voici comment notre solution répond à ces besoins :"+

    "<ul><li><span style=\"text-decoration: underline; weight:600;\">Dématérialisation / e-ticket</span> : Dites adieu au papier ! Notre solution vous permet de passer au numérique en proposant des e-tickets, éliminant ainsi le gaspillage de papier et contribuant à la préservation de l'environnement.</li>"+

"<li><span style=\"text-decoration: underline; weight:600;\">Optimisation du temps de travail</span> : Nous comprenons que le temps des comités d'entreprise est précieux. Notre plateforme vous aide à gagner du temps en automatisant de nombreuses tâches administratives fastidieuses, vous permettant ainsi de vous concentrer sur des activités plus importantes.</li>"+

"<li><span style=\"text-decoration: underline; weight:600;\">Commande autonome des employés </span>: Les employés peuvent désormais commander des tickets en toute autonomie. Plus besoin de passer par leur responsable du CSE ni de respecter des horaires stricts. Ils peuvent accéder aux réductions de prix du comité d'entreprise quand cela leur convient.</li>"+

"<li><span style=\"text-decoration: underline; weight:600;\">Validation et utilisation facile des tickets </span>: Notre interface conviviale permet de vérifier rapidement la validité des tickets et de les utiliser en toute simplicité. Fini les tracas liés à la gestion des tickets physiques.</li>"+

    "<li><span style=\"text-decoration: underline; weight:600;\">Interfaçage avec les besoins métier </span>: Grâce à notre système d'API sécurisé, notre solution peut être intégrée à vos besoins métier spécifiques. Vous pouvez par exemple régler et lancer une partie de bowling, ouvrir une porte, et bien plus encore, le tout de manière sécurisée et transparente.</li>"+

"<li><span style=\"text-decoration: underline;\">Maîtrise du budget CE </span>: Offrez aux CSE la tranquillité d'esprit en leur permettant de maîtriser leur budget. Notre solution vous offre un contrôle total sur les dépenses en facilitant la gestion des avantages, ce qui en fait un argument commercial de choix pour attirer les comités d'entreprise.</li></ul>"+

    "Notre solution de vente de ticket numérique est conçue pour simplifier la vie des comités d'entreprise, rendre les employés plus autonomes et offrir une gestion plus efficace des avantages. Rejoignez-nous dès aujourd'hui pour faire évoluer votre CE vers un avenir sans papier, plus efficace et plus connecté.",
    subTitle:
        '',
    images:[ce1,ce2,ce3,ce4]
  },
]

export default CardData
