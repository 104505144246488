import React from 'react'
import HomeAndAboutHead from './HomeAndAboutHead'
import about from '../src/images/about.png'
import Home from '../src/Home';
import Service from '../src/Service';
import About from '../src/About';
import Contact from '../src/Contact';
import Formation from '../src/Formation';

const OnePage = () => {
  return (
    <>
      <Home/>
      <Service/>
      <Formation/>
      <About/>
      <Contact/>
    </>
  )
}

export default OnePage
