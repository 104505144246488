import React from 'react'
import HomeAndAboutHead from './HomeAndAboutHead'
//import about from '../src/images/about.png'
import about from '../src/images/newcolorabout.png'

const About = () => {
  return (
    <div style={{paddingTop:20}} id="about">
      <HomeAndAboutHead
        headtitle={'À propos de'}
        headtitle2={'Nous'}
        headSubTitle={
          'Honey Digital, est spécialisée dans la conception et le développement de plateformes digitales.' +
          '\r\nNous accompagnons les entreprises physiques vers une conversion numérique.\r\n\r\n'+
              'Nous nous appuyons sur un noyau dur de 3 ingénieurs freelance.\r\n Nous nous sommes rencontrés au sein d’une entreprise spécialisée dans la digitalisation il y a plus de 5 ans et avons décidé de nous émanciper. Nous bénéficions d’un vaste  réseau d’indépendants qui nous permet de répondre à tous les besoins et ce, quelque soit le domaine fonctionnel, au juste prix.\r\n' +
          'Nous pouvons intervenir sur l’ensemble des étapes d’un projet, de l’audit à la maintenance, en passant par la rédaction des spécifications fonctionnelles et techniques, la gestion de projet, le développement et la livraison.\r\n' +
          'Nous sommes également capables d’intervenir sur une solution déjà en production qui ne répond pas à vos exigences et sommes capables d’y remédier dans des délais compétitifs.\n'
        }
        headButton={'Connect Now'}
        headButtonLink={'/contact'}
        headImg={about}
      />
    </div>
  )
}

export default About
