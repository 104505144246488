import React from 'react'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap.bundle'
import Navbar from './Navbar'
import { MessengerChat  } from 'react-messenger-chat-plugin';
import { Switch, Route, Redirect } from 'react-router-dom'
import Home from './Home'
import About from './About'
import Service from './Service'
import Contact from './Contact'
import OnePage from './onePage'
import Project from './Project'
import Footer from './Footer'
import Particles from "react-tsparticles";

function App() {
  return (
    <>
        <Particles
            id="tsparticles"
            options={{
                fpsLimit: 120,
                interactivity: {
                    "detect_on": "canvas",
                    "events": {
                        "onhover": {
                            "enable": false,
                            "mode": "repulse"
                        },
                        "onclick": {
                            "enable": false,
                            "mode": "push"
                        },
                        "resize": true
                    },
                    "modes": {
                        "grab": {
                            "distance": 400,
                            "line_linked": {
                                "opacity": 1
                            }
                        },
                        "bubble": {
                            "distance": 400,
                            "size": 40,
                            "duration": 2,
                            "opacity": 8,
                            "speed": 3
                        },
                        "repulse": {
                            "distance": 200,
                            "duration": 0.4
                        },
                        "push": {
                            "particles_nb": 4
                        },
                        "remove": {
                            "particles_nb": 2
                        }
                    }
                },
                particles: {
                    "number": {
                        "value": 76,
                        "density": {
                            "enable": true,
                            "value_area": 4734.960581453156
                        }
                    },
                    "color": {
                        "value": "#fbb60d"
                    },
                    "shape": {
                        "type": "polygon",
                        "stroke": {
                            "width": 0,
                            "color": "#fbb60d"
                        },
                        "polygon": {
                            "nb_sides": 6
                        },
                        "image": {
                            "src": "img/github.svg",
                            "width": 100,
                            "height": 100
                        }
                    },
                    "opacity": {
                        "value": 0.2,
                        "random": false,
                        "anim": {
                            "enable": false,
                            "speed": 1,
                            "opacity_min": 0.1,
                            "sync": false
                        }
                    },
                    "size": {
                        "value": 15.783201938177186,
                        "random": true,
                        "anim": {
                            "enable": false,
                            "speed": 0,
                            "size_min": 40.388946161284391,
                            "sync": false
                        }
                    },
                    "line_linked": {
                        "enable": false,
                        "distance": 150,
                        "color": "#ffffff",
                        "opacity": 0.4,
                        "width": 1
                    },
                    "move": {
                        "enable": true,
                        //"speed": 3.156640387635437,
                        "speed": 1.156640387635437,
                        "direction": "bottom-right",
                        "random": true,
                        "straight": true,
                        "out_mode": "out",
                        "bounce": false,
                        "attract": {
                            "enable": false,
                            "rotateX": 631.3280775270874,
                            "rotateY": 868.0761065997452
                        }
                    }
                },
                detectRetina: true,
            }}
        />
        <Navbar />
      <Switch>
        <Route exact path='/' component={OnePage} />
        <Route exact path='/about' component={About} />
        <Route exact path='/service' component={Service} />
        <Route exact path='/contact' component={Contact} />
        <Route exact path='/test' component={Home} />
        <Route exact path='/project/:named' component={Project} />
        <Redirect to='/' />
      </Switch>

        <MessengerChat
            pageId='110514518275102'
            language='fr_FR'
            themeColor={'#fbb60d'}
            height={24}
            loggedInGreeting='Bonjour!'
            loggedOutGreeting='Bonjour'
            autoExpand={true}
            debugMode={false}
            onMessengerShow={() => {console.log('onMessengerShow')}}
            onMessengerHide={() => {console.log('onMessengerHide')}}
            onMessengerDialogShow={() => {console.log('onMessengerDialogShow')}}
            onMessengerDialogHide={() => {console.log('onMessengerDialogHide')}}
        />
      <Footer />
    </>
  )
}

export default App
