import React, { useState,useEffect,useCallback } from 'react'
import contact from './images/contactusv2.png'
import mail from './images/icons8-mail-50.png'
import phone from './images/icons8-phone-50.png'
import pin from './images/icons8-pin-64.png'
import { useLocation,useHistory } from "react-router-dom";
import CardData from './CardData'
import ImageViewer from 'react-simple-image-viewer';

const Project = () => {
    let history = useHistory();
    const [item, setItem]= useState(null)
    const params = useLocation();
    console.log(params)
    const nameuri =params.pathname.split("/").pop()

    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    useEffect(()=>{
        if (params.state?.item && params.state) {
            setItem(params.state?.item)
        }else{
            var result = CardData.find(t=>t.nameUri === nameuri);
            if(result){
                setItem(result)
            }else{
                history.push({
                    pathname: '/',
                });
            }
        }
    },[])



    return (
    <>
      <div id="project" style={{width:"70%",margin:"0 auto", marginTop:30, display:"flex",flexDirection:"column",background:"white"}}>
            <div style={{width:"100%",justifyContent:"center", display:"flex", background:"white" }}>
                <img style={{display:"block",width:"100%", maxWidth:"100%", height:"auto"}} src={item?.imgcover}/>
            </div>
          <div style={{width:"100%", paddingTop:80, display:"flex", background:"white"}}>
              <div className="detail">
                  {item?.partenariat ? (
                      <h5>Partenariat</h5>
                  ):(
                  <h5>Détails du projet</h5>
                  )}
                  <div class="description" style={{textAlign: "justify"}} dangerouslySetInnerHTML={{ __html: item?.description }} />
              </div>
              <div className="info" style={{paddingLeft:15, paddingRight:15, background:"white"}}>
                  <div style={{display:"flex", justifyContent:"flex-end"}}>
                      {item?.interne?(<h5>Produit</h5>):(<h5>Client</h5>)}
                      <div style={{marginLeft:10}}>{item?.title}</div>
                  </div>
                  <div style={{display:"flex", justifyContent:"flex-end"}}>
                      <h5>Date</h5>
                      <div style={{marginLeft:10}}>{item?.date}</div>
                  </div>
                  <div>
                      {item?.skills.length!=0 &&(
                      <h5 style={{display:"flex", justifyContent:"flex-end"}}>Compétence</h5>)}
                      <div style={{display:"flex", flexWrap:"wrap", justifyContent:"space-between"}}>{item?.skills?.map((value,index)=>{
                          return(
                              <img width={60} src={value}/>
                              // <div style={{padding:7,marginTop:5, border:"1px solid", borderRadius:30}}>
                              //   {value}
                              // </div>
                          )
                      })}</div>
                  </div>
                  {item?.href?(
                      <a style={{margin:"20px auto", cursor:"pointer", display:"flex", width:171}} className="btn-get-started" href={item?.href}>Voir le projet</a>
                 ):null}
              </div>
          </div>
          <div style={{width:"100%", paddingTop:80, display:"flex", flexWrap:"wrap", background:"white"}} >
              {item?.images?.map((src, index) => (
                  <img
                      src={ src }
                      onClick={ () => openImageViewer(index) }
                      height={300}
                      key={ index }
                      style={{ margin: '2px', cursor:"pointer" }}
                      alt=""
                  />
              ))}
              {isViewerOpen && (
                  <ImageViewer
                      backgroundStyle={{zIndex:9999}}
                      src={ item?.images }
                      currentIndex={ currentImage }
                      disableScroll={ false }
                      closeOnClickOutside={ true }
                      onClose={ closeImageViewer }
                  />
              )}

          </div>
      </div>
    </>
  )
}

export default Project
