import React from 'react'

const Footer = () => {
  return (
    <>
      <div style={{height:100}}>

      </div>
    </>
  )
}

export default Footer
